export function parseDate(date, timestamp = false, mes = true) {
	if (date == null || date == undefined || date == "") return "";
	let fecha;

	if (!isNaN(Number(date))) fecha = new Date(Number(date));
	else
		fecha = new Date(
			String(date)
				.replaceAll(/\\/g, "")
				.split(" ")[0]
		);

	fecha.setHours(0, 0, 0, 0);

	if (timestamp) return fecha.getTime();

	const MESES = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];
	let day = `0${fecha.getDate()}`.slice(-2);
	let month = mes
		? MESES[fecha.getMonth()]
		: `0${fecha.getMonth() + 1}`.slice(-2);
	let year = fecha.getFullYear();

	return [day, month, year].join(" ");
}

export function humanFileSize(bytes, dp = 1, units = "file") {
  const thresh = 1000;

  let unitArray;
  switch (units) {
    case "file":
      unitArray = ["b", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      break;
    case "length":
      unitArray = ["mm", "m", "km"];
      break;
    case "area":
      unitArray = ["m2", "km2"];
      break;
  }

	if (Math.abs(bytes) < thresh) {
    return bytes + unitArray[0];
  }

  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < unitArray.length - 1
  );

	console.log(bytes, unitArray, u)

  return bytes.toFixed(dp) + " " + unitArray[u];
}

export function obtenerNombreTarifaML(codTarifa) {
	switch (codTarifa) {
		case "001":
		case "004":
		case "005":
		case "006":
		case "007":
		case "008":
			return {actual: "2.0TD ML", antes: '2.0A ML'}; //2.0A ML
		case "003":
			return {actual: "3.0TD ML", antes: '3.0A ML'}; //3.0A ML
		case "011":
			return {actual: "6.1TD ML", antes: '3.1A ML'}; //3.1A ML
		case "012":
			return {actual: "6.1TD ML", antes: '6.1A ML'}; //6.1A ML
		case "013":
			return {actual: "6.2TD ML", antes: '6.2 ML'}; //6.2 ML
		case "014":
			return {actual: "6.3TD ML", antes: '6.3 ML'}; //6.3 ML
		case "015":
			return {actual: "6.4TD ML", antes: '6.4 ML'}; //6.4 ML
		case "016":
			return {actual: "6.4TD ML", antes: '6.5 ML'}; //6.5 ML
		case "017":
			return {actual: "6.1B ML", antes: '6.1B ML'}; //6.1B ML
		case "018":
			return {actual: "2.0TD ML"};
		case "019":
			return {actual: "3.0TD ML"};
		case "020":
			return {actual: "6.1TD ML"};
		case "021":
			return {actual: "6.2TD ML"};
		case "022":
			return {actual: "6.3TD ML"};
		case "023":
			return {actual: "6.4TD ML"};
		default:
			return "";
	}
}

export function calcularConsumoAnual(consumos, cups, tarifa) {
	try {
		let datos = consumos
			.filter(({ CodigoCUPS }) => cups.includes(CodigoCUPS))
			.sort((a, b) => (new Date(a.FechaFin) > new Date(b.FechaFin) ? 1 : -1))
			.slice(-12);
		let a1 = null;
		let a2 = null;
		let a3 = null;
		let a4 = null;
		let a5 = null;
		let a6 = null;
		let total = null;

		if (datos.length == 0) {
			return { a1, a2, a3, a4, a5, a6, total };
		}

		datos.forEach(
			({ Activa1, Activa2, Activa3, Activa4, Activa5, Activa6 }) => {
				total += Activa1 + Activa2 + Activa3 + Activa4 + Activa5 + Activa6;

				a1 += Activa1;
				a2 += Activa2;
				a3 += Activa3;
				a4 += Activa4;
				a5 += Activa5;
				a6 += Activa6;
			}
		);

		let finUltimoConsumo = new Date(datos[datos.length - 1].FechaFin).getTime();

		let inicioUltimoConsumo = new Date(datos[0].FechaInicio).getTime();

		let Y = (finUltimoConsumo - inicioUltimoConsumo) / 864e5;

		a1 = Math.round((a1 / Y) * 365);
		a2 = Math.round((a2 / Y) * 365);
		a3 = Math.round((a3 / Y) * 365);
		a4 = Math.round((a4 / Y) * 365);
		a5 = Math.round((a5 / Y) * 365);
		a6 = Math.round((a6 / Y) * 365);
		total = Math.round((total / Y) * 365);

		"004" != tarifa && "006" != tarifa
			? ("003" != tarifa && "011" != tarifa) ||
			  ((a1 = a1 + a4),
			  (a2 = a2 + a5),
			  (a3 = a3 + a6),
			  (a4 = null),
			  (a5 = null),
			  (a6 = null))
			: ((a3 = a2), (a2 = null));

		[a1, a2, a3, a4, a5, a6, total] = [a1, a2, a3, a4, a5, a6, total].map((v) =>
			v == 0 ? null : v
		);

		return { a1, a2, a3, a4, a5, a6, total };
	} catch (err) {
		console.error(err);
	}
	// ES0021000010708399HX
	// console.log(total);
}

export function downloadFile(file, open = false) {
  let a = document.createElement("a");
  a.href = URL.createObjectURL(file);
  if (
    open &&
    ![
      "iPad",
      "iPhone",
      "iPod",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad Simulator",
      "Macintosh",
      "MacIntel",
      "MacPPC",
      "Mac68K",
      "Pike v7.6 release 92",
      "Pike v7.8 release 517",
    ].includes(navigator.platform)
  )
    a.target = "_blank";
  else a.download = file.name;
  a.click();
}